import { API_URL_BASE, API_URL } from '@/config/configs';

const PermissaoModuloUsuario = {
    datatable: {
        id_datatable: 'permissaomodulousuario',
        chave_primaria: 'id_permissao_modulo_usuario',
        url: `${API_URL_BASE}${API_URL.erp.permissaomodulousuario.datatable}`,
        colunas: false,
    },
    formRefsComponente: 'PermissaoModuloUsuarioForm',
    formNome: 'formPermissaoModuloUsuario',
    moduloNome: 'permissaomodulousuario',
    chavePrimaria: 'id_permissao_modulo_usuario',
    componenteArquivo: 'PermissaoModuloUsuario/Form.vue',
    tabRefs: 'tabPermissaoModuloUsuario',
    urlNovoRegistro: '',
    urlConsultaRegistro: 'listaPorIdForm',
    tabs: []
}

export { PermissaoModuloUsuario };

